import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import NavbarWrapper from 'common/src/components/Navbar';
import Drawer from 'common/src/components/Drawer';
import Button from 'common/src/components/Button';
import Logo from 'common/src/components/UIElements/Logo';
import Box from 'common/src/components/Box';
import HamburgMenu from 'common/src/components/HamburgMenu';
import Container from 'common/src/components/UI/Container';
import { DrawerContext } from 'common/src/contexts/DrawerContext';
import ScrollSpyMenu from 'common/src/components/ScrollSpyMenu';

import LogoImage from 'common/src/assets/image/portfolio/susieyy.png';
import LogoImageAlt from 'common/src/assets/image/portfolio/susieyy-alt.png';

import Dropdown from 'common/src/components/Dropdown'
import SocialProfile from '../SocialProfile';
import { withTrans } from '../../i18n/withTrans'

////
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
// import { LanguageSwitcher } from '../LanguageSwitcher'

const LanguageSwitcher = () => {
  const {i18n} = useTranslation();

  const setEnglish = useCallback(() => {
    i18n.changeLanguage('en');
  }, [i18n]);

  const setJapanease = useCallback(() => {
    i18n.changeLanguage('ja');
  }, [i18n]);

  return (
    <div>
      <button
        onClick={setEnglish}
      >
        English
      </button>
      <button
        onClick={setJapanease}
      >
        Japanease
      </button>
    </div>
  );
};
/////

const Navbar = ({ navbarStyle, logoStyle, button, row, menuWrapper, t }) => {
  const {i18n} = useTranslation();

  const Data = useStaticQuery(graphql`
    query {
      portfolioJson {
        SOCIAL_PROFILES {
          iconGroup
          iconName
          url
        }
        MENU_ITEMS {
          label
          path
          offset
        }
      }
    }
  `);

  const { state, dispatch } = useContext(DrawerContext);

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  
  // const currentLanguage = i18n.language || window.localStorage.i18nextLng || ''

  return (
    <NavbarWrapper {...navbarStyle} className="portfolio_navbar">
      <Container noGutter mobileGutter width="1200px">
        <Box {...row}>
          <Logo
            href="/"
            logoSrc={LogoImage}
            title="Portfolio"
            logoStyle={logoStyle}
            className="main-logo"
          />
          <Logo
            href="/"
            logoSrc={LogoImageAlt}
            title="Portfolio"
            logoStyle={logoStyle}
            className="logo-alt"
          />
          <Box {...menuWrapper}>
            <ScrollSpyMenu
              className="main_menu"
              menuItems={Data.portfolioJson.MENU_ITEMS}
              offset={-70}
            />

            <SocialProfile items={Data.portfolioJson.SOCIAL_PROFILES} className="main_menu" />
            {/* <LanguageSwitcher />
            <Dropdown content="TEST" dropdownItems={['as', 'a']}/> */}

            <Drawer
              width="420px"
              placement="right"
              drawerHandler={<HamburgMenu barColor="#3444f1" />}
              // open={state.isOpen}
              toggleHandler={toggleHandler}
            >
              <ScrollSpyMenu
                className="mobile_menu"
                menuItems={Data.portfolioJson.MENU_ITEMS}
                drawerClose={true}
                offset={-100}
              />
              {/* <a className="navbar_drawer_button" href="#1">
                <Button {...button} title={currentLanguage} />
              </a>               */}
            </Drawer>
          </Box>
        </Box>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object,
};

Navbar.defaultProps = {
  navbarStyle: {
    minHeight: '70px',
    display: 'block',
  },
  row: {
    flexBox: true,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  logoStyle: {
    maxWidth: ['120px', '130px'],
  },
  button: {
    type: 'button',
    fontSize: '16px',
    pl: '0',
    pr: '0',
    colors: 'primary',
    minHeight: 'auto',
  },
  menuWrapper: {
    flexBox: true,
    alignItems: 'center',
  },
};

export default withTrans(Navbar);
